<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Volunteering" subtitle="Help make the convention a success by volunteering for available roles and slots." :tabs="tabs" />

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="No assignments found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Status" />
				<app-table-column text="Role" />
				<app-table-column text="Slot" />
				<app-table-column />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onRoleClick(item.role.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-label text="Confirmed" type="green" v-if="item.status === $constants.volunteers.assignment.status.confirmed" />
					<app-table-cell-label text="Pending" type="yellow" v-if="item.status === $constants.volunteers.assignment.status.pending" />
					<app-table-cell-label text="Rejected" type="red" v-if="item.status === $constants.volunteers.assignment.status.rejected" />

					<app-table-cell-text :text="item.role.name" />

					<app-table-cell-text :text="item.slot.name" v-if="item.role.type === $constants.volunteers.role.type.schedule" />
					<app-table-cell-text :can-empty="true" v-if="item.role.type !== $constants.volunteers.role.type.schedule" />

					<app-table-cell-action icon="volunteers.clash" theme="red" v-tooltip="'Clashing slot'" v-on:click="onJoinClick(item.slot.id)" :large="true" v-if="item.slot.session.can.join && item.slot.session.is.clashing" />
					<app-table-cell-action icon="volunteers.join" theme="white" v-tooltip="'Volunteer'" v-on:click="onJoinClick(item.slot.id)" :large="true" v-if="item.slot.session.can.join && !item.slot.session.is.clashing" />
					<app-table-cell-action icon="volunteers.leave" theme="white" v-tooltip="'Cancel volunteer'" v-on:click="onLeaveClick(item.slot.id)" :large="true" v-if="item.slot.session.can.leave" />

					<app-table-cell-action icon="volunteers.info" theme="white" v-tooltip="'Read more about this role'" v-on:click="onRoleClick(item.role.id)" :large="true" />

				</app-table-row>

			</app-table-body>

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: '80px auto 250px 32px 32px',
			references: {
				days: []
			},
			pagination: false,
			endpoint: 'convention/volunteers/assignments'
		}

	},

	created: function() {

		if (!this.$canVolunteer) this.$router.push({
			name: 'Convention.Volunteers.Register'
		})

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Volunteers.Roles', text: 'Roles' },
				{ name: 'Convention.Volunteers.Slots', text: 'Slots' },
				{ name: 'Convention.Volunteers.Assignments', text: 'My Assignments', count: this.$store.getters['session/count'].volunteers.total }
			]

		}

	},

	methods: {

		onRoleClick: function(id) {

			this.$router.push({
				name: 'Convention.Volunteers.Role',
				params: {
					id: id
				},
				query: {
					redirect: 'assignments'
				}
			})

		},

		onJoinClick: function(id) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignment',
				params: {
					id: id
				},
				query: {
					redirect: 'assignments'
				}
			})

		},

		onLeaveClick: function(id) {

			this.$router.push({
				name: 'Convention.Volunteers.Assignment',
				params: {
					id: id
				},
				query: {
					redirect: 'assignments'
				}
			})

		}

	}

}

</script>

<style scoped>

</style>